import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientSettings(ctx, queryParams) {
      console.log("ClientSettings Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/manage/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
 

    
  },
}
