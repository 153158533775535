<template>

  <b-card>
      <b-row>
        <h4 class="   font-weight-bold mx-1 mb-2">
          Module List
        </h4>
        <b-col md="12">
         
  
          <!-- draggable -->
          <draggable
            :list="permissionsData"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in permissionsData"
              :key="index"
              tag="li"
            >
              <div class="d-flex">
                <b-avatar :text="listItem.title.slice(0,3)" />
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.title }}
                  </b-card-text>
                  <small>{{ listItem.title }}</small>
                </div>
              </div>
              <div>
                <b-list-group>
      <b-list-group-item 
      class="d-flex justify-content-between align-items-center"
      v-for="(featureItem, i) in listItem.ModuleFeatures" :key="i"
      >
        <span>{{featureItem.title}}</span>
        <b-form-checkbox
            
            :checked="resolveFeatureStatus( featureItem.id ,featureItem.ProjectModuleId )"
            name="check-button"
            @change="toogleFeature(featureItem.id ,featureItem.ProjectModuleId )"
            switch
            inline
          >   {{ featureItem.id }} -  {{ featureItem.ProjectModuleId }} 
            <span>{{resolveFeatureStatus( featureItem.id ,featureItem.ProjectModuleId )?'Active&nbsp;&nbsp;':'Disabled'}}</span>  {{featureItem.monthlyPrice}}
          </b-form-checkbox>
      </b-list-group-item>
  
      
   
    </b-list-group>
                </div>
            </b-list-group-item>
          </draggable>
        </b-col>
       
        
        <!--/ application switch -->
  
      
      </b-row>
    </b-card>
    </template>
    
    <script>
    import {
     BAvatar, BButton, BRow, BCol, BCard,BCardHeader, BCardTitle, BCardText, BFormCheckbox,BBadge, BListGroup, BListGroupItem ,BTable
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import axios from "@axios";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import vSelect from 'vue-select'
  import store from '@/store' 
  import draggable from 'vuedraggable'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { useToast } from 'vue-toastification/composition'
    export default {
      components: {
        BButton,BAvatar,draggable,
        BTable,
        BRow,
        BCol,
        BCard,BCardHeader, BCardTitle,BCardText,
        BBadge,
        BFormCheckbox, BListGroup, BListGroupItem 
      },
      directives: {
        Ripple,
      },
      props: {
        accountPreferences: {
          type: Object,
          default: () => {
           
          },
        },
      },
     
       created() {
       
          this.fetchCategories();
    


       
        // this.localSettings = this.accountPreferences.data==null||JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings ==null?
        // {  useCustomLogin: false }: JSON.parse(JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings );//his.generalData;
        // console.log(this.localSettings);
        // console.log("account preferences");
      
    
    
      },
      data() {
       
    
        return {
          modules:null,
          isLoading: false,
          isUpdated: false,
         
          permissionsData:null,
          localSettings: {
               useCustomLogin: false ,
               anotherSetting: false,
        },
        }
      },
      setup(props,context) {
        const clientRoles = ref(null)
        const currentClientID = ref(null)
        const toast = useToast();
        console.log("setup");
        console.log(props.accountPreferences.data);
        currentClientID.value=props.accountPreferences.data.ClientId;

        
        
        clientRoles.value=[];
      

          axios
            .get('manage/clientmodules?id='+currentClientID.value )
            .then((response)=> { 
             
              clientRoles.value= JSON.parse(JSON.stringify(response.data.data));
            console.log("matched client roles");
                console.log(  clientRoles.value);
  
              }
              )
            .catch(error => reject(error))

            const  resolveFeatureStatus   = (feautreid,projectmoduleid) => {
      console.log("incoming status",feautreid);
    
      console.log(clientRoles.value);
      console.log("found Client roles: ",clientRoles.value.length);
  
      var module = clientRoles.value.find(o => o.ProjectModuleId === projectmoduleid);
      var obj;
        if (module)
        {

        
        obj = module.ClientModuleFeatures.find(o => o.ModuleFeatureId === feautreid);
      }
      console.log(obj);
      if (obj ===undefined) { 
        console.log("returning false");
        return false; }
      
      console.log("returning true");
      return true;
    }

        
      
       
      // UnRegister on leave
  
    const  toogleFeature   = (feautreId,modulid) => {
     console.log("toggle");
      console.log(status);
      var projectModule=modulid;
      var featureId=feautreId;

       
  
      let obj = clientRoles.value.find(o => o.id === featureId);
      console.log(obj);
  
      
      var data={
            moduleFeatureId:featureId,
            moduleId:projectModule,
            id:currentClientID.value
          };
          console.log("Add Module:",projectModule);
          console.log("Add Feautre",featureId);
          console.log("For Client",currentClientID.value);
    
        
          axios({
            method: "post",
            url: `/manage/updateclientmodule`,
            data:data ,
          })
            .then((r) => {
  
              if (obj ===undefined) {
        clientRoles.value.push(status);
        console.log("pushing");
      }else{
        clientRoles.value.splice(clientRoles.value.indexOf(obj), 1);
        console.log("removing");
      }; 
  
              toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Success`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: "Your changes have been saved! and It will effect on next login",
              
                },
              });
              
            })
            .catch((r) => {
               toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertOctagonIcon",
                  variant: "warning",
                  text: "Something went wrong!",
                },
              });
              
            })
  
      
      
       
      return true;
    }
  
      
  
      return {
        resolveFeatureStatus,
        toogleFeature
      }
    },
      methods: {
        
         
       
  
        fetchCategories( ) {
        console.log("fetchCategories fetch");
        return new Promise((resolve, reject) => {
          axios
            .get('/manage/modules' )
            .then((response)=> { 
             
           
             var data=  response.data;
             var responseData=JSON.parse(  JSON.stringify( data));
          console.log("Fetched All module Features");
            console.log(responseData.data);
               this.permissionsData=responseData.data;
            
  
           
  
              }
              )
            .catch(error => reject(error))
        })
      },
         sendInformation(featureId,moduleId) {
          
          var data={
            moduleFeatureId:featureId,
            moduleId:moduleId,
          };
    
          this.isLoading = true;
          axios({
            method: "post",
            url: `/manage/updateclientmodule`,
            data:data ,
          })
            .then((r) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Success`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: "Your changes have been saved! and It will effect on next login",
                },
              });
              this.isLoading = false;
              this.isUpdated = false;
            })
            .catch((r) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "AlertOctagonIcon",
                  variant: "warning",
                  text: "Something went wrong!"+ r,
                },
              });
              this.isLoading = false;
            });
          return;
        },
        resetForm() {
          this.localSettings = JSON.parse(JSON.stringify(this.accountPreferences))
        },
      },
    }
    </script>
    