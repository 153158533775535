<template>
    <b-card>
  
      <!-- media -->
      <b-media no-body 
      v-if="!newClient"
      
      >
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="optionsLocal.logo"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>
  
        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
             :state="Boolean(file)"
            v-model="file"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="fileSelected"
          />
          <!--/ upload button -->
  
          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
  
  
      <!-- form -->
      <b-form :class=" newClient ? 'mt-0' : 'mt-2'">
  
        <b-row>
          <b-col v-if="newClient" sm="12">
            
            <b-form-group
              label="Client Title"
              label-for="client-title"
            >
              <b-form-input
                v-model="clientInformation.clienttitle"
                name="clienttitle"
                placeholder="Client title"
              />
            </b-form-group>
          </b-col>
            <b-col v-else sm="12">
            
            <b-form-group
              label=" Legal Name"
              label-for="account-company"
            >
              <b-form-input
                v-model="optionsLocal.title"
                name="company"
                placeholder="Company name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          
          <b-col sm="6" v-if="newClient">
            <b-form-group
              label="Client Email"
              label-for="client-email"
            >
              <b-form-input
                v-model="clientInformation.clientemail"
                placeholder="Client Email"
                name="clientemail"
              />
            </b-form-group>
          </b-col>
  
          <b-col v-else sm="6">
            <b-form-group
              label="Account Manager Name"
              label-for="account-username"
            >
              <b-form-input
                v-model="optionsLocal.contactname"
                placeholder="Account Manager Name"
                name="username"
              />
            </b-form-group>
          </b-col>
  
          <b-col v-if="newClient" sm="6">
            <b-form-group
              label="Admin Email"
              label-for="admin-email"
            >
              <b-form-input
                v-model="clientInformation.email"
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col v-else sm="6">
            <b-form-group
              label="Contact Number"
              label-for="account-name"
            >
              <b-form-input
                v-model="optionsLocal.contactphone"
                name="name"
                placeholder="Mobile"
              />
            </b-form-group>
          </b-col>
  
          <b-col v-if="newClient" sm="6">
            <b-form-group
              label="Admin Title"
              label-for="admin-title"
            >
              <b-form-input
                v-model="clientInformation.fullname"
                name="fullname"
                placeholder="Enter name"
              />
  
            </b-form-group>
          </b-col>
  
          <b-col v-else sm="6">
            <b-form-group
              label="Primary E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="optionsLocal.contactmail"
                name="email"
                placeholder="Email"
              />
  
            </b-form-group>
          </b-col>
  
          <b-col v-if="newClient" sm="6">
            <b-form-group
              label="Admin Password"
              label-for="admin-password"
            >
              <b-form-input
                v-model="clientInformation.password"
                name="password"
                placeholder="Enter password"
              />
  
            </b-form-group>
          </b-col>
  
  
      
          <!-- alert -->
          
          <b-col
            v-if="!newClient"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->
  
          <b-col 
          cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="sendInformation"
            >
              {{newClient ? 'Submit' : 'Save changes' }}
            </b-button>
            <b-button
            v-if="!newClient"
  
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </template>
  
  <script>
  import {
    BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref } from '@vue/composition-api'
  import axios from "@axios";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  
  export default {
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
    },
    directives: {
      Ripple,
    },
    props: {
      newClient:{
        type:Boolean,
        required:true,
      },
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    created() {
       console.log("account created");
   
      this.optionsLocal =  JSON.parse(JSON.parse(JSON.stringify(this.generalData.data)).generalsettings );//this.generalData;
      
      console.log( 'spo',this.optionsLocal);
      console.log("account created");
  
  
    },
    data() {
      return {
        clientInformation:{
          "clientemail": "",
          "email": "",
          "password": "",
          "fullname": "",
          "clienttitle": ""
        },
        optionsLocal:{},// this.generalData.generalsettings,
        profileFile: null,
           file: null,
        isUpdated: false,
            isLoading: false,
      }
    },
    methods: {
      resetForm() {
        this.optionsLocal = this.generalData.generalsettings;
      },
       resetFile() {
        this.file = null;
        this.profileFile = null;
      },
      clearFiles() {
        this.$refs["file-input"].reset();
      },
       fileSelected(payload) {
        const formData = new FormData();
        formData.append("file", payload);
        formData.append("courseId", this.courseId);
        formData.append("acc", 3);
        formData.append("platform", "webadmin");
        this.isLoading = true;
  
        axios
          .post("/upload/admin", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            this.optionsLocal.logo = response.data.url;
            this.isUpdated = true;
            this.isLoading = false;
            this.$bvToast.toast(`${response.data.message}`, {
              title: "Success",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "success",
              solid: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.$bvToast.toast(`${error.response.data.message}`, {
              title: "Error",
              autoHideDelay: 5000,
              appendToast: true,
              variant: "danger",
              solid: true,
            });
          });
      },
  
        handleError(err){
          console.log('err',err.response.data.message);
          this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `${err.response.data.message}`,
                icon: "AlertOctagonIcon",
                variant: "warning",
              },
            });
        },
  
       sendInformation() {
        console.log(this.optionsLocal);
    
  
        this.isLoading = true;
        
        if(this.$route.name=='add-new-client'){
          axios.post('/manage/createclient',{
           ...this.clientInformation
          }).then((res)=>{
            console.log(res);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `${res.data.message}`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
          }).catch((err)=>{
            this.handleError(err)
          })
        }
        else{
        axios({
          method: "put",
          url: `/manage/settings`,
          data: {
            id:1,
            info: "generalinformation",
            file: null,
            content: this.optionsLocal,
          },
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          })
          .catch((r) => {
            this.handleError(r)
            this.isLoading = false;
          });
        return;
      }
      }
    },
    setup() {
      const refInputEl = ref(null)
      const previewEl = ref(null)
  
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
  
      return {
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
    },
  }
  </script>
  